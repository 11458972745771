import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Menu, MenuItem, IconButton } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import useStyles from "./Filters.style";
import { useTranslation } from "react-i18next";
import { getCursorFromDate } from "./LogsUtils";
import AddIcon from "@material-ui/icons/Add";
import FilterComponent from "./FilterComponent";
import clsx from "clsx";

const Filters = (props) => {
  const {
    //Options Arrays
    verticalOptions,
    buyerOptions,
    vendorOptions,
    statusOptions,
    tierOptions,
    buyerCampaignOptions,
    vendorCampaignOptions,
    eventOptions,
    logTypeOptions,
    janglIdOptions,
    durationOptions,
    //Inputs
    date,
    setDate,
    verticalTags,
    buyerTags,
    vendorTags,
    statusTags,
    searchTags,
    janglIdTags,
    durationTags,
    tierTags,
    buyerCampaignTags,
    eventTypeTags,
    logTypeTags,
    vendorCampaignTags,
    setHasExecuted,
    setCursor,
    staticFilters,
    filterComponents,
    setFilterComponents,
    filters,
    initialCursor,
    setInitialCursor,
    isCursorSet,
    setIsCursorSet,
    onExecute,
    filtersEdited,
    setFiltersEdited
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const addFilterHandler = (item) => {
    setAnchorEl(null);
    setFilterComponents(filterComponents.concat(item));
  };

  /**
   * Set filters date time value
   * @param {*} dateVal
   * @param {*} isWithTime
   */
  const onSetDateTime = (dateVal) => {
    const currentCursor = getCursorFromDate(dateVal);
    if (
      (verticalTags.length ||
        buyerTags.length ||
        vendorTags.length ||
        statusTags.length ||
        durationTags.length ||
        searchTags.length ||
        tierTags.length ||
        buyerCampaignTags.length ||
        vendorCampaignTags.length ||
        eventTypeTags.length ||
        janglIdTags.length ||
        filterComponents.length) && !isCursorSet
    ) {
      setCursor(currentCursor);
      setInitialCursor(currentCursor);
      setDate(dateVal);
      setHasExecuted(false);
      setFiltersEdited(true);
      setIsCursorSet(true);
    } else if (initialCursor !== currentCursor) {
      setCursor(currentCursor);
      setInitialCursor(currentCursor);
      setDate(dateVal);
      setHasExecuted(false);
      setFiltersEdited(true);
    }
  };

  useEffect(() => {
    //filters.forEach((item) => {});//TODO:update state
    staticFilters[0].tags = eventTypeTags;
    staticFilters[0].options = eventOptions;
    staticFilters[1].tags = statusTags;
    staticFilters[1].options = statusOptions;

    filters[0].tags = verticalTags;
    filters[0].options = verticalOptions;
    filters[1].tags = tierTags;
    filters[1].options = tierOptions;
    filters[2].tags = vendorTags;
    filters[2].options = vendorOptions;
    filters[3].tags = vendorCampaignTags;
    filters[3].options = vendorCampaignOptions;
    filters[4].tags = buyerTags;
    filters[4].options = buyerOptions;
    filters[5].tags = buyerCampaignTags;
    filters[5].options = buyerCampaignOptions;
    filters[6].tags = janglIdTags;
    filters[6].options = janglIdOptions;
    filters[7].tags = durationTags;
    filters[7].options = durationOptions;
    filters[8].tags = searchTags;
  }, [
    buyerCampaignOptions,
    vendorCampaignOptions,
    janglIdOptions,
    verticalOptions,
    vendorOptions,
    tierOptions,
    statusOptions,
    durationOptions,
    buyerOptions,
    vendorTags,
    buyerTags,
    verticalTags,
    statusTags,
    durationTags,
    searchTags,
    tierTags,
    eventOptions,
    filters,
    buyerCampaignTags,
    vendorCampaignTags,
    eventTypeTags,
    logTypeTags,
    logTypeOptions,
    janglIdTags,
    staticFilters]);

  const removeItem = (event) => {
    //to clear selected filters tags if any
    const item = filterComponents.find((item) => item.id === event);
    item.setTagsHandler([]);
    //to remove filter from list
    setFilterComponents(filterComponents.filter((item) => item.id !== event));
  };
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        alignItems="stretch"
        flex-grow={1}
        justify="flex-start"
        className={classes.filterTextDate}
      >
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="stretch"
          flex-grow={1}
          justify="flex-start"
          className={clsx(classes.filterTextDate, classes.filterDiv)}
        >
          <Grid item className={classes.filterDynamic}>
            <Typography className={classes.filterTextDate}>
              {t("DATE")}
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                label=""
                inputVariant="outlined"
                format="MM/dd/yyyy h:mm a"
                value={date}
                onChange={onSetDateTime}
                maxDate={new Date()}
                className={classes.datefieldstyle}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          {staticFilters.map((item, index) => (
            <FilterComponent
              id={item.id}
              key={item.id + index}
              label={item.label}
              options={item.options}
              tags={item.tags}
              setTags={item.setTags}
              setTagsHandler={item.setTagsHandler}
              search={item.search}
              setSearch={item.setSearch}
              type={item.type}
              removeItem={removeItem}
              canRemove={false}
            />
          ))}
          {filterComponents.map((item, index) => (
            <FilterComponent
              id={item.id}
              key={item.id + index}
              label={item.label}
              options={item.options}
              tags={item.tags}
              setTags={item.setTags}
              setTagsHandler={item.setTagsHandler}
              search={item.search}
              setSearch={item.setSearch}
              type={item.type}
              removeItem={removeItem}
              inputRef={item.ref}
            />
          ))}
        </Grid>
        <Grid
          item
          container
          direction="row"
          spacing={2}
          alignItems="flex-start"
          flex-grow={1}
          justify="center"
          className={clsx(classes.filterTextDate, classes.iconBtn)}
        >
          <Grid className={clsx(classes.buttonDiv, classes.btnDiv)}>
            <IconButton
              className={classes.iconButton}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              disabled={filters.length === filterComponents.length}
            >
              <AddIcon color="primary" className={classes.iconLarge} />
            </IconButton>
            <Button
              variant="contained"
              color="default"
              className={classes.addButton}
              onClick={(event) => onExecute(null)}
              disabled={!filtersEdited}
            >
              {t("SEARCH")}
            </Button>
          </Grid>
          <Menu
            id="menu-campaign"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            {filters
              .filter((item) => !filterComponents.includes(item))
              .map((item, index) => {
                return (
                  item.hide === true ? '' :
                    <MenuItem
                      key={item.id}
                      value={item.id}
                      onClick={() => addFilterHandler(item)}
                    >
                      {item.label}
                    </MenuItem>
                );
              })}
          </Menu>
        </Grid>
      </Grid>
    </>
  );
};

export default Filters;
