import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dashboardMainDiv: {
    padding: theme.spacing(0, 3, 6, 3)
  },
  content: { display: "block" },
  icon: { color: theme.palette.primary.main },
  iconDisabled: { color: theme.palette.primary.grey },
  buttonDiv: {
    justifyContent: "space-between",
    display: "flex",
    marginTop: "20px"
  },
  popupBox: {
    overflow: "auto",
    height: "80vh",
    width: "1100px",
    overflowX: "hidden"
  },
  popupValue: {
    fontSize: "16px",
    color: theme.palette.primary.darkbgcolor,
    textAlign: "left",
    marginTop: "10px !important",
    noWrap: true,
    paddingLeft: "20px",
    height: "24px"
  },
  popupValueUrl: {
    fontSize: "16px",
    color: theme.palette.primary.darkbgcolor,
    textAlign: "left",
    marginTop: "10px !important",
    paddingLeft: "20px",
    height: "24px",
    wordBreak: "break-all"
  },
  popupValueDescription: {
    fontSize: "16px",
    color: theme.palette.primary.darkbgcolor,
    textAlign: "left",
    marginTop: "10px !important",
    noWrap: true,
    paddingLeft: "20px",
    whiteSpace: "pre",
    height: "auto"
  },
  popupValueLink: {
    fontSize: "16px",
    textAlign: "left",
    marginTop: "10px !important",
    noWrap: true,
    height: "24px",
    color: theme.palette.primary.main,
    textDecoration:"none",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  popupValueRaw: {
    fontSize: "16px",
    color: theme.palette.primary.darkbgcolor,
    textAlign: "left",
    marginTop: "10px !important",
    noWrap: true,
    width: "100%",
    marginRight: "-60px",
    wordBreak: "break-all"
  },
  popupHeader: {
    fontSize: "20px",
    color: theme.palette.primary.darkbgcolor,
    textAlign: "left",
    margin: "10px 0 10px 0",
    fontWeight: "bold !important",
    borderBottom: "4px solid " + theme.palette.primary.main
  },
  popupTitle: {
    fontSize: "16px",
    color: theme.palette.primary.darkbgcolor,
    textAlign: "right",
    marginTop: "10px !important",
    fontWeight: "bold !important",
    height: "24px"
  },
  popupHeading: {
    marginTop: "10px",
    fontSize: "25px",
    fontWeight: "normal",
    display: "inline-block",
    paddingBottom: "5px",
    color: theme.palette.primary.darkbgcolor,
    flexShrink: "true"
  },
  metaGrid: {
    marginTop: "20px",
    paddingRight: "55px",
    paddingLeft: "70px"
  },
  popUpParent: {
    overflow: "hidden",
    height: "400px",
    width: "580px",
    overflowX: "hidden"
  },
  formattedText: {
    fontFamily: "courier !important",
    wordBreak: "break-all"
  },
  popupValueMultiLine: {
    fontSize: "16px",
    color: theme.palette.primary.darkbgcolor,
    textAlign: "left",
    overflow: "auto",
    whiteSpace: "pre-wrap",
    marginTop: "10px !important"
  },
  modalIdText: {
    paddingLeft: "20px"
  },
  tab: {
    backgroundColor: theme.palette.primary.lightWhite
  },
  panelTabsDiv: {
    paddingLeft: "15px",
    paddingTop: "15px"
  },
  menu: {
    fontSize: "1.5rem"
  }
}));
