import reducer from "./reducer";

import * as verticalSelectors from "./selectors";
import * as verticalOperations from "./operations";
import * as verticalActions from "./actions";
import * as verticalTypes from "./types";

export {
  verticalSelectors,
  verticalOperations,
  verticalActions,
  verticalTypes
};

export default reducer;
