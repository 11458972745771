import { loaderOperations } from "../loader";
import { getUser, getCsrfTokenAPI } from "../../services/api";
import * as actions from "./actions";

export const getAndSaveUserInfo = (callBackFn) => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());
      localStorage.setItem("isLoggedIn", true);
      const userResponse = await getUser();
      localStorage.setItem("userEmail", userResponse.data.email);
      dispatch(actions.fetchUserSuccess(userResponse.data));
      dispatch(loaderOperations.uiStopLoading());
      callBackFn(userResponse.data);
    } catch (err) {
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

export const getUserInfo = () => {
  return async (dispatch) => {
    try {
      if (localStorage && !localStorage.getItem("isLoggedIn")) {
        localStorage.setItem("isLoggedIn", true);
      }
      dispatch(loaderOperations.uiStartLoading());
      const userResponse = await getUser();
      dispatch(actions.fetchUserInfoSuccess(userResponse.data));
      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};

export const getCsrfToken = () => {
  return async (dispatch) => {
    try {
      dispatch(loaderOperations.uiStartLoading());
      await getCsrfTokenAPI();
      dispatch(loaderOperations.uiStopLoading());
    } catch (err) {
      dispatch(loaderOperations.uiStopLoading());
    }
  };
};
