import { createSelector } from "reselect";

const featureSelector = ({ verticals }) => verticals;

/**
 * WEBLEADS get vertical data
 */
export const getVerticalsData = createSelector(featureSelector, ({ list }) =>
  list?.records?.length && list.records.map((row) => ({
    ...row,
    default_sell_price:
      row.default_sell_price !== undefined ? "$" + row.default_sell_price : null
  }))
);

/**
 * CALLS get vertical data
 */
export const getVerticalsDataCalls = createSelector(
  featureSelector,
  ({ list }) =>
    list?.records?.length &&  list.records.map((row) => ({
      ...row,
      default_margin:
        row.default_margin !== undefined ? row.default_margin + "%" : null
    }))
);
