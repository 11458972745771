import React from "react";
import { Grid, Typography } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import clsx from "clsx";
import { TabPanel } from "jangl-common-js";
import theme from "../../CustomTheme";
import { formatDateTime } from "../Dashboard/LogsUtils";

//Modal for Weblead Logs
export const modalLogs = (
  setting,
  row,
  classes,
  t,
  tabPopup,
  setTabPopUp,
  tabPopupResponse,
  setTabPopUpResponse
) => (
  <>
    <Grid
      container
      direction="column"
      justify="center"
      spacing={0}
      className={classes.metaGrid}
    >
      <div>
        <Typography className={classes.popupHeading}>
          {row.event_name}
        </Typography>
      </div>
      <Grid container direction="row" justify="flex-start" spacing={1}>
        <Grid item xs={2}>
          <Typography className={classes.popupTitle}>
            {t("TIMESTAMP")}
          </Typography>
          <Typography className={classes.popupTitle}>
            {t("JANGL_ID")}
          </Typography>
          <Typography className={classes.popupTitle}>{t("VENDOR")}</Typography>
          <Typography className={classes.popupTitle}>
            {t("VENDOR_CAMPAIGN")}
          </Typography>
          {row.buyer_name && (
            <Typography className={classes.popupTitle}>
              {t("ENDPOINT")}
            </Typography>
          )}
          {row.buyer_campaign_id && (
            <Typography className={classes.popupTitle}>
              {t("BUYER_CAMPAIGN")}
            </Typography>
          )}
          {row.integration_name && (
            <Typography className={classes.popupTitle}>
              {t("INTEGRATION")}
            </Typography>
          )}
          <Typography className={classes.popupTitle}>
            {t("VERTICAL")}
          </Typography>
          <Typography className={classes.popupTitle}>{t("RESULT")}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography noWrap className={classes.popupValue}>
            {row.timestamp}
          </Typography>
          <Typography noWrap className={classes.popupValue}>
            {row.jangl_id}
          </Typography>
          <Typography noWrap className={classes.popupValue}>
            { <a className={classes.popupValueLink} href={'/admin/vendors/' + row.vendor_id + '/'} target="_blank" rel="noreferrer">{row.vendor_name}</a>}

          </Typography>
          <Typography noWrap className={classes.popupValue}>
            { <a className={classes.popupValueLink} href={'/admin/vendors/' + row.vendor_id + '/'+setting+'/'+ row.vendor_campaign_id } target="_blank" rel="noreferrer">{row.vendor_campaign_id}</a>}
          </Typography>
          {row.buyer_name && (
            <Typography noWrap className={classes.popupValue}>
              { <a className={classes.popupValueLink} href={'/admin/buyers/' + row.buyer_id + '/'} target="_blank" rel="noreferrer">{row.buyer_name}</a>}
            </Typography>
          )}
          {row.buyer_campaign_id && (
            <Typography noWrap className={classes.popupValue}>
              { <a className={classes.popupValueLink} href={'/admin/buyers/' + row.buyer_id + '/'+setting+'/'+ row.buyer_campaign_id} target="_blank" rel="noreferrer">{row.buyer_campaign_id}</a>}
            </Typography>
          )}
          {row.integration_name && (
            <Typography noWrap className={classes.popupValue}>
              {row.integration_name}
            </Typography>
          )}
          <Typography noWrap className={classes.popupValue}>
            {row.vertical_name}
          </Typography>
          <Typography noWrap className={classes.popupValueDescription}>
            {row.description}
          </Typography>
        </Grid>
      </Grid>

      {row && (row.error || row.exception || row.validation) && (
        <>
          <div>
            <hr />
            <Typography className={classes.popupHeading}>
              {t("ERROR_LABEL")}
            </Typography>
          </div>
          {row.error && (
            <Grid container direction="row" justify="flex-start" spacing={1}>
              <Grid item xs={2}>
                <Typography className={classes.popupTitle}>
                  {t("MESSAGE")}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography noWrap className={classes.popupValue}>
                  {row.error}
                </Typography>
              </Grid>
            </Grid>
          )}

          {row.exception && (
            <Grid container direction="row" justify="flex-start" spacing={1}>
              <Grid item xs={2}>
                <Typography className={classes.popupTitle}>
                  {t("EXCEPTION")}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography noWrap className={classes.popupValue}>
                  {row.exception}
                </Typography>
              </Grid>
            </Grid>
          )}

          {row.validation && (
            <Grid container direction="row" justify="flex-start" spacing={1}>
              <Grid item xs={2}>
                <Typography className={classes.popupTitle}>
                  {t("VALIDATION")}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography noWrap className={classes.popupValue}>
                  {row.validation}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {row && row.request_timestamp && (
        <>
          <div>
            <hr />
            <Typography className={classes.popupHeading}>
              {t("REQUEST")}
            </Typography>
          </div>
          <Grid container direction="row" justify="flex-start" spacing={1}>
            <Grid item xs={2}>
              <Typography className={classes.popupTitle}>
                {t("TIMESTAMP")}
              </Typography>
              <Typography className={classes.popupTitle}>
                {t("METHOD")}
              </Typography>
              <Typography className={classes.popupTitle}>{t("URL")}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography noWrap className={classes.popupValue}>
                {formatDateTime(row.request_timestamp)}
              </Typography>
              <Typography noWrap className={classes.popupValue}>
                {row.request_method}
              </Typography>
              <Typography className={classes.popupValueUrl}>
                {row.request_url}
              </Typography>
              <div className={classes.panelTabsDiv}>
                <TabPanel
                  parentStyle={classes.tab}
                  name={t("DATA_POPUP")}
                  theme={theme}
                  tab={tabPopup}
                  setTab={setTabPopUp}
                  tabPanels={[
                    {
                      content: (
                        <div>
                          <Typography
                            className={clsx(
                              classes.popupValueMultiLine,
                              classes.formattedText
                            )}
                          >
                            {row.request_formatted}
                          </Typography>
                        </div>
                      ),
                      padding: true
                    },
                    {
                      content: (
                        <div>
                          <Typography
                            className={clsx(
                              classes.popupValueRaw,
                              classes.formattedText
                            )}
                          >
                            {row.request_data}
                          </Typography>
                        </div>
                      ),
                      padding: true
                    },
                    {
                      content: (
                        <div>
                          <Typography
                            className={clsx(
                              classes.popupValueMultiLine,
                              classes.modalIdText,
                              classes.formattedText
                            )}
                          >
                            {(row.headers && ReactHtmlParser(row.headers)) ||
                              ReactHtmlParser(row.request_headers)}
                          </Typography>
                        </div>
                      ),
                      padding: true
                    }
                  ]}
                  tabList={[
                    { id: "1", label: t("FORMATTED") },
                    { id: "2", label: t("RAW") },
                    { id: "3", label: t("HEADERS") }
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
      {row && row.response_timestamp && (
        <>
          <div>
            <hr />
            <Typography className={classes.popupHeading}>
              {t("RESPONSE")}
            </Typography>
          </div>
          <Grid container direction="row" justify="flex-start" spacing={1}>
            <Grid item xs={2}>
              <Typography className={classes.popupTitle}>
                {t("TIMESTAMP")}
              </Typography>
              <Typography className={classes.popupTitle}>
                {t("DURATION")}
              </Typography>
              <Typography className={classes.popupTitle}>
                {t("STATUS_CODE")}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography noWrap className={classes.popupValue}>
                {formatDateTime(row.response_timestamp)}
              </Typography>
              <Typography noWrap className={classes.popupValue}>
                {row.duration}
              </Typography>
              <Typography noWrap className={classes.popupValue}>
                {row.response_code}
              </Typography>

              <div className={classes.panelTabsDiv}>
                <TabPanel
                  parentStyle={classes.tab}
                  name={t("DATA_POPUP_RESPONSE")}
                  tab={tabPopupResponse}
                  setTab={setTabPopUpResponse}
                  theme={theme}
                  tabPanels={[
                    {
                      content: (
                        <div>
                          <Typography
                            className={clsx(
                              classes.popupValueMultiLine,
                              classes.formattedText
                            )}
                          >
                            {row.response_formatted}
                          </Typography>
                        </div>
                      ),
                      padding: true
                    },
                    {
                      content: (
                        <div>
                          <Typography
                            className={clsx(
                              classes.popupValueRaw,
                              classes.formattedText
                            )}
                          >
                            {row.response_data}
                          </Typography>
                        </div>
                      ),
                      padding: true
                    },
                    {
                      content: (
                        <div>
                          <Typography
                            className={clsx(
                              classes.popupValueMultiLine,
                              classes.modalIdText,
                              classes.formattedText
                            )}
                          >
                            {row.request_headers &&
                              ReactHtmlParser(row.response_headers)}
                          </Typography>
                        </div>
                      ),
                      padding: true
                    }
                  ]}
                  tabList={[
                    { id: "1", label: t("FORMATTED") },
                    { id: "2", label: t("RAW") },
                    { id: "3", label: t("HEADERS") }
                  ]}
                />
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  </>
);
