import * as types from "./types";

const fetchAuthSuccess = (record) => ({
  type: types.FETCH_AUTH_SUCCESS,
  payload: record
});

const fetchUserSuccess = (record) => ({
  type: types.FETCH_USER_SUCCESS,
  payload: record
});

const fetchSiteInfoSuccess = (record) => ({
  type: types.FETCH_SITE_INFO_SUCCESS,
  payload: record
});

const fetchUserInfoSuccess = (record) => ({
  type: types.FETCH_USER_INFO_SUCCESS,
  payload: record
});

export {
  fetchAuthSuccess,
  fetchUserSuccess,
  fetchSiteInfoSuccess,
  fetchUserInfoSuccess
};
