import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import { useTranslation } from "react-i18next";

export const CustomRoutes = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const getJanglIdFromHash = (hash) => {
    const match = hash.match(/#\/(\w+)/);
    return match ? match[1] : null;
  };
  const janglId = getJanglIdFromHash(location.hash);

  return (
    <Switch>
      <Route
        exact
        path="/admin/calls/logs/"
        component={(props) => (
          <Dashboard {...props} janglId={janglId} setting={t("CALLS_PARAM")} />
        )}
      />
      <Route
        exact
        path="/admin/webleads/logs/"
        component={(props) => (
          <Dashboard {...props} janglId={janglId} setting={t("WEBLEADS_PARAM")} />
        )}
      />
    </Switch>
  );
};

export default CustomRoutes;
